body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #f9fafc;
  font-family: system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

* {
  box-sizing: border-box;
}

.no-focus:focus {
  outline: none;
  box-shadow: none;
}

/* elrond dapp tools overwrites - there is no other way at the moment and I don't want to use react-bootstrap */
.card-body {
  text-align: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-body h4.mb-4,
.card-body h4.mb-3 {
  font-size: 24px;
  margin-top: 0;
}

.card-body h4.mb-3 {
  margin-bottom: 10px;
}

.card-body h4.mb-4,
.card-body h4.mb-3,
.card-body p.lead.mb-0,
.card-body p.mb-4,
.card-body a.btn.btn-primary {
  text-align: center;
  font-family: 'SF UI Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
}

.card-body .btn.btn-primary {
  margin: 10px;
  padding: 10px;
  display: -webkit-inline-flex;
  background: #fff;
  border: 1px solid #c1c4d6;
  border-radius: 4px;
  cursor: pointer;
}

.card-body a.btn.btn-primary {
  color: inherit;
  font-size: 13.3333px;
  text-decoration: none;
}

.state.m-auto.p-4.text-center {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
/* elrond dapp tools overwrites end */

.hidden {
  display: none;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.underline {
  text-decoration: underline;
}

.text-right {
  text-align: right;
}

.rounded {
  border-radius: 0.25rem;
}

.max-w-full {
  max-width: 100%;
}

.h-auto {
  height: auto;
}

.pulsate {
  background: linear-gradient(-45deg, #dddddd, #f0f0f0, #dddddd, #f0f0f0);
  background-size: 400% 400%;
  animation: gradient-pulsate 2.25s ease infinite;
}

@keyframes gradient-pulsate {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.nftPanel {
  position: relative;
  width: 100%;
}
@media only screen and (min-width: 680px) {
  .nftPanel {
    width: calc(50% - 20px);
  }
}
@media only screen and (min-width: 1200px) {
  .nftPanel {
    width: calc(33% - 20px);
  }
}
/* @media only screen and (min-width: 1200px) {
  .nftPanel {
    width: calc(25% - 20px);
  }
} */
.nftPanel .badge {
  position: absolute;
  margin: -32px auto;
  padding: 0.125rem 0.5rem;
  left: 50%;
  transform: translate(-50%, 0);

  text-align: center;
  font-weight: 600;
  border-radius: 1rem;
  font-size: 14px;
}

.nftPanel .badge.soldout {
  background-color: #db4444;
  color: #fff;
}

.logo {
  width: 3rem;
  height: 3rem;
  margin-top: 0.35rem;
}
@media only screen and (min-width: 580px) {
  .logo {
    width: 250px;
    height: 46px;
  }
}
